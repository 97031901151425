<template>
  <span>
    <template v-if="type === 'image-regular' || type === 'image-circle'">
      <div
        class="file-input"
        :class="type"
      >
        <div class="image-container">
          <img
            v-if="type === 'image-regular'"
            :src="imageRegular"
            title=""
          >
          <img
            v-else
            :src="imageCircle"
            title=""
          >
        </div>
        <div class="button-container">
          <md-button
            v-if="hasImage"
            class="md-danger md-round"
            @click="removeImage"
          ><i class="fa fa-times" />Remove</md-button>
          <md-button class="md-success md-round md-fileinput">
            <template
              v-if="type === 'image-regular' && !hasImage"
            >Select image</template>
            <template
              v-else-if="type === 'image-circle' && !hasImage"
            >Add photo</template>
            <template
              v-else
            >Change</template>
            <input
              type="file"
              :name="type"
              @change="onFileChange"
            >
          </md-button>
        </div>
      </div>
    </template>

    <template v-else-if="type === 'input'">
      <template v-if="!withButton">
        <md-field class="form-file-upload form-file-simple">
          <md-input
            :id="inputName + 'Visible'"
            v-model="inputModel"
            :placeholder="placeholder"
            @click="triggerInputFileHidden(inputName + 'Hidden')"
          />
          <input
            :id="inputName + 'Hidden'"
            type="file"
            @change="newValue(inputName + 'Visible', inputName + 'Hidden')"
          >
        </md-field>
      </template>
      <template v-if="withButton">
        <div class="form-file-group">
          <md-field class="form-file-upload form-file-simple">
            <md-input
              :id="inputName + 'Visible'"
              v-model="inputModel"
              :multiple="multiple"
              :placeholder="placeholder"
              @click="triggerInputFileHidden(inputName + 'Hidden')"
            />
            <input
              v-if="!multiple"
              :id="inputName + 'Hidden'"
              type="file"
              @change="newValue(inputName + 'Visible', inputName + 'Hidden')"
            >
            <input
              v-else
              :id="inputName + 'Hidden'"
              type="file"
              :multiple="multiple"
              @change="
                newValueMultiple(inputName + 'Visible', inputName + 'Hidden')
              "
            >
          </md-field>
          <md-button
            class="md-success md-round md-just-icon"
            @click="triggerInputFileHidden(inputName + 'Hidden')"
          ><md-icon>{{ btnIcon }}</md-icon></md-button>
        </div>
      </template>
    </template>
  </span>
</template>

<script>
/* eslint-disable */
export default {
  name: 'FileUpload',
  props: {
    type: String,
    inputName: String,
    inputValue: String,
    placeholder: String,
    multiple: Boolean,
    btnIcon: String,
    withButton: Boolean,
  },
  data() {
    return {
      inputModel: null,
      inputModel2: null,
      imageRegular: require('@/client/_theme/assets/img/image_placeholder.jpg'),
      imageCircle: require('@/client/_theme/assets/img/placeholder.jpg'),
      hasImage: false,
    };
  },
  methods: {
    onFileChange(e) {
      e.preventDefault();
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const file = files[0];
      const reader = new FileReader();
      const vm = this;
      reader.onload = () => {
        if (this.type === 'image-circle') {
          vm.imageCircle = reader.result;
        } else {
          vm.imageRegular = reader.result;
        }
      };
      reader.readAsDataURL(file);
      vm.hasImage = true;
    },
    removeImage() {
      if (this.type === 'image-circle') {
        this.imageCircle = require('@/client/_theme/assets/img/placeholder.jpg');
      } else {
        this.imageRegular = require('@/client/_theme/assets/img/image_placeholder.jpg');
      }
      this.hasImage = false;
    },
    triggerInputFileHidden(hiddenID) {
      const element = document.getElementById(hiddenID);
      element.click();
    },
    newValue(visibleID, hiddenID) {
      const element = document.getElementById(hiddenID);
      const visibleElem = document.getElementById(visibleID);
      const elemValue = element.value;
      const filename = elemValue.replace(/C:\\fakepath\\/i, '');

      visibleElem.value = filename;
    },
    newValueMultiple(visibleID, hiddenID) {
      const element = document.getElementById(hiddenID);
      const visibleElem = document.getElementById(visibleID);
      let names = '';
      for (let i = 0; i < element.files.length; ++i) {
        if (i < element.files.length - 1) {
          names += `${element.files.item(i).name}, `;
        } else {
          names += element.files.item(i).name;
        }
      }

      visibleElem.value = names;
    },
  },
};
</script>

<style lang="css"></style>
