



























































































































































































































































































/* eslint-disable */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// @ts-ignore
import { FullBgCard, InfoAreas } from '@/client/components/_theme';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';
import api from '../util/api';

@Component({
  components: {
    FullBgCard,
    InfoAreas,
    RestStatus,
    Alert,
  },
})
export default class Cost extends Vue {
  name = '';
  email = '';
  message = '';
  boolean = false;
  loading: boolean = false;
  error: boolean = false;
  responseType: string = '';
  alertMessage: string = '';
  showAlert: boolean = false;

  cardAbout: any = {
    image1: '/solar-panel-head.jpg',
    image2: '/solar-panel-foot.jpg',
  };

  // eslint-disable-next-line class-methods-use-this
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }

  resetForm() {
    this.message = '';
    this.name = '';
    this.email = '';
    this.boolean = false;
  }

  resetAlert() {
    this.alertMessage = '';
    this.responseType = '';
    this.showAlert = false;
  }

  popAlert(response: any) {
    this.responseType = response.status === 200 ? 'success' : 'danger';
    this.alertMessage = response.message;
    this.showAlert = true;
  }

  submitLead() {
    this.resetAlert();
    this.loading = true;

    const lead = {
      name: this.name,
      email: this.email,
      message: this.message,
    };
    
    api('POST', 'https://rarequaticfinds.herokuapp.com/api/leads/question', lead)
      .then((response: any) => {
        this.loading = false;
        this.resetForm();
        this.popAlert(response);
      });
  }
}
