import { DropDown, Parallax, MobileMenu } from '@/client/components/_theme';
import { VPopover } from 'v-tooltip';
import ProductZoomer from 'vue-product-zoomer';
import { Carousel, CarouselItem } from 'element-ui';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('drop-down', DropDown);
    Vue.component('parallax', Parallax);
    Vue.component('v-popover', VPopover);
    Vue.component('mobile-menu', MobileMenu);
    Vue.use(ProductZoomer);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
  },
};

export default GlobalComponents;
