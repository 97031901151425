import Vue from 'vue';
import Router from 'vue-router';
import { parseString } from 'xml2js';
import Landing from './client/components/Landing.vue';
import About from './client/components/About.vue';
import GetStarted from './client/components/GetStarted.vue';
import Terms from './client/components/Terms.vue';
import Cities from './client/components/cities/Cities.vue';
import Frisco from './client/components/cities/Frisco.vue';
import Mckinney from './client/components/cities/Mckinney.vue';
import Prosper from './client/components/cities/Prosper.vue';
import Colony from './client/components/cities/Colony.vue';
import Plano from './client/components/cities/Plano.vue';
import Dallas from './client/components/cities/Dallas.vue';
import Arlington from './client/components/cities/Arlington.vue';
import Garland from './client/components/cities/Garland.vue';
import Lewisville from './client/components/cities/Lewisville.vue';
import FortWorth from './client/components/cities/FortWorth.vue';
import NotFound from './client/components/NotFound.vue';
import Cost from './client/components/Cost.vue';
import api from './client/util/api';

Vue.use(Router);

const routeArray = [
  {
    path: '/',
    name: 'welcome',
    component: Landing,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/cities',
    name: 'cities',
    redirect: '/cities/frisco',
    component: Cities,
    children: [
      {
        path: 'frisco',
        name: 'frisco',
        component: Frisco,
      },
      {
        path: 'plano',
        name: 'plano',
        component: Plano,
      },
      {
        path: 'mckinney',
        name: 'mckinney',
        component: Mckinney,
      },
      {
        path: 'prosper',
        name: 'prosper',
        component: Prosper,
      },
      {
        path: 'colony',
        name: 'colony',
        component: Colony,
      },
      {
        path: 'lewisville',
        name: 'lewisville',
        component: Lewisville,
      },
      {
        path: 'dallas',
        name: 'dallas',
        component: Dallas,
      },
      {
        path: 'fortworth',
        name: 'fortworth',
        component: FortWorth,
      },
      {
        path: 'arlington',
        name: 'arlington',
        component: Arlington,
      },
      {
        path: 'garland',
        name: 'garland',
        component: Garland,
      },
    ],
  },
  {
    path: '/cost',
    name: 'cost',
    component: Cost,
  },
  {
    path: '/get-started',
    name: 'start',
    component: GetStarted,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '*',
    redirect: {
      name: 'not-found',
    },
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior(to, from) {
    return new Promise((resolve: Function) => {
      setTimeout(() => {
        if (to.name !== from.name) {
          resolve(window.scrollTo({ top: 0, behavior: 'smooth' }));
        } else {
          resolve();
        }
      }, 300);
    });
  },
  routes: routeArray,
});

export default router;
