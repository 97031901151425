import axios from 'axios';

export default async function api(method: any, endpoint: string, params?: any) {
  return axios(
    {
      method,
      url: endpoint,
      data: params,
    },
  )
    .catch((err) => {
      console.log(err);
      throw err;
    });
}
