

















































import { Component, Vue } from 'vue-property-decorator';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';
import api from '../util/api';
// eslint-disable-next-line
// @ts-ignore
import { InfoAreas, Tabs, Parallax } from './_theme';

@Component({
  components: {
    Alert,
    InfoAreas,
    Tabs,
    Parallax,
    RestStatus,
  },
})
export default class GetStarted extends Vue {
  boolean = false;
  image = 'hex-bg.jpeg';
  year: number = new Date().getFullYear();
  name = '';
  email = '';
  location = '';
  alertMessage = '';
  responseType = '';
  showAlert = false;
  loading = false;
  error = false;

  contentLeft: Array<any> = [
    {
      colorIcon: 'success',
      icon: 'request_quote',
      title: 'Free Quote',
      description:
        'Get a no hassle quote today, we will find you the best prices for your area.',
    },
    {
      colorIcon: 'info',
      icon: 'speed',
      title: 'Responsive',
      description:
        "Don't like to wait? Neither do we. We'll get back to you within 24 hours.",
    },
  ];

  get isFormValidated() {
    return this.validateForm();
  }

  // eslint-disable-next-line class-methods-use-this
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }

  resetForm() {
    this.name = '';
    this.email = '';
    this.location = '';
  }

  resetAlert() {
    this.alertMessage = '';
    this.responseType = '';
    this.showAlert = false;
  }

  popAlert(response: any) {
    this.responseType = response.status === 200 ? 'success' : 'danger';
    this.alertMessage = response.data;
    this.showAlert = true;
  }

  // eslint-disable-next-line class-methods-use-this
  validateEmail(email: string) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateForm(): boolean {
    if (this.name.length > 3) {
      if (this.validateEmail(this.email) && this.boolean) {
        return true;
      }
    }

    return false;
  }

  submitLead() {
    this.resetAlert();

    const lead = {
      name: this.name,
      email: this.email,
      location: this.location,
    };

    api('POST', 'https://rarequaticfinds.herokuapp.com/api/leads/main', lead)
      .then((response: any) => {
        console.log(response);
        this.loading = false;
        this.resetForm();
        this.popAlert(response);
      })
      .catch((err) => {
        const res = {
          status: 500,
          data: 'Unable to submit lead, maybe this is a duplicate?',
        };

        this.popAlert(res);
        this.error = true;
        throw err;
      });
  }
}
