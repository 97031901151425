import VueMaterial from 'vue-material';
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import GlobalMixins from './globalMixins';

export default function install(Vue) {
  Vue.use(VueMaterial);
  Vue.use(GlobalComponents);
  Vue.use(GlobalDirectives);
  Vue.use(GlobalMixins);
}
